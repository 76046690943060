/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css');

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300&display=swap');

body {
  font-display: fallback;
  font-family: 'Open Sans', sans-serif;
  color: #444444;
}

a {
  text-decoration: none !important;
  /* color: #010203 !important;*/
}

a:hover {
  color: #ffd700 !important;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-display: fallback;
  font-family: 'Merriweather Sans', sans-serif;
}

#main {
  margin-top: 90px;
}

.icon-rotate {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.break-flex {
  flex-basis: 100%;
  height: 0;
}

.cursor-pointer {
  cursor: pointer;
}
/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 998;
  background: #010203;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}

.back-to-top i:hover {
  color: #ffd700;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  transition: all 0.5s;
  z-index: 997;
  transition: all 0.5s;
  padding: 24px 0;
  background: #010203;
}

#header.header-transparent {
  background: transparent;
}
/*
#navbar > ul > li > a {
  color:black
}
*/
#header.header-scrolled {
  background: #010203;
  padding: 12px;
}

.gold-text {
  color: #f7d76c;
}

#header .logo h6 {
  margin-bottom: 0.3rem;
}

a.nav-link.scrollto {
  color: #fff;
}

#header .logo img {
  padding: 0;
  margin: 0;
  max-height: 40px;
}

@media (max-width: 768px) {
  #header.header-scrolled {
    padding: 15px 0;
  }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  margin-left: 10px;
  font-family: 'Raleway', sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: black;
  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
  background: rgba(255, 255, 255, 0.2);
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 10px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 15px;
  text-transform: none;
  font-weight: 600;
  color: #2f4d5a;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
  color: #f6c324;
}

.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }
  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}
.grow {
  animation-name: grow;
}

@keyframes grow {
  0% {
    transform: scale(0, 0);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes shrink {
  0% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(0, 0);
  }
}

.hidden {
  display: none;
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #fff;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }
  .navbar ul {
    display: none;
  }
  .nav-link > img {
    margin-left: 15px;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(255, 215, 0, 0.5);
  transition: 0.3s;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

#navbar > ul > li > a:hover {
  background-color: black;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a {
  padding: 10px 20px;
  font-size: 15px;
  color: #f6c324 !important;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
  color: #f6c324 !important;
}

.navbar-mobile .getstarted {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a {
  color: red;
}

.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  /*linear-gradient(
      rgba(240, 220, 130, 0.5),
      rgba(248, 222, 126, 0.2)
    ),
    */
  width: 100%;
  height: 100vh;
  background: linear-gradient(rgba(7, 7, 7, 0.3), rgba(255, 255, 255, 0.5)),
    url('./img/sean-pollock-2.webp') top center;

  background-size: cover;
  position: relative;
  margin-bottom: -90px;
}

.w-60 {
  width: 60vw;
}

.w-70 {
  width: 70vw;
}

.map-width {
  width: 90vw;
}
#hero .hero-container {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
/*
#hero h1 {
  margin: 0 0 10px 0;
  font-size: 64px;
  font-weight: 700;
  line-height: 56px;
  text-transform: uppercase;
  color: #fff;
}
*/

#hero h1 {
  color: #f6c324;
  margin-bottom: 50px;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 700;
}

#hero .btn-get-started {
  font-size: 36px;
  display: inline-block;
  padding: 4px 0;
  border-radius: 50px;
  transition: 0.3s ease-in-out;
  margin: 10px;
  width: 64px;
  height: 64px;
  text-align: center;
  color: #fff !important;
}

#hero .btn-get-started:hover {
  padding-top: 8px;
  background: rgba(255, 255, 255, 0.15);
  color: #f7d76c !important;
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}

@media (max-width: 768px) {
  #hero h1 {
    font-size: 2em;
    line-height: 36px;
  }
  #hero h2 {
    margin-top: 1em;
    font-size: 1.5em;
    margin-bottom: 30px;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg {
  box-shadow: 0 0 3rem 0 rgba(0, 0, 0, 0.8);
  background-color: #f6c324;

  backdrop-filter: blur(5px);
}

.section-bg-black-bacgkround {
  box-shadow: 0 0 3rem 0 rgba(0, 0, 0, 0.8);
  background: url('./img/old-black-background-grunge-texture-dark-wallpaper-blackboard-chalkboard-room-wall-_1_.webp')
    top center;
  background-size: cover;
  position: relative;

  backdrop-filter: blur(5px);
}
.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h1 {
  font-size: 32px;
  font-weight: bold;
  position: relative;
  margin-bottom: 30px;
  color: #f6c324;
  z-index: 2;
  font-display: fallback;
}

.dark-text {
  color: black !important;
}
.dark-text a {
  color: black;
}

.white-text {
  color: white !important;
}

.section-title h2::after {
  content: '';
  position: absolute;
  right: 50%;
  transform: translate(-50%, -50%);
  transform: rotate(180deg);
  width: 122px;
  height: 66px;
  z-index: -1;
}

.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  background-color: #f8fbfd;
  min-height: 40px;
}

.breadcrumbs h1 {
  font-size: 24px;
  font-weight: 300;
  font-display: fallback;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-display: fallback;
}

.breadcrumbs ol li + li {
  padding-left: 10px;
}

.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #6c757d;
  content: '/';
}

.Contact {
  justify-content: center;
}

@media (max-width: 768px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }
  .breadcrumbs ol {
    display: block;
  }
  .breadcrumbs ol li {
    display: inline-block;
  }
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about {
  background: url('./img/about-bg.webp') center center no-repeat;
  background-size: cover;
  padding: 60px 0;
  position: relative;
}

.about::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.75);
  z-index: 9;
}

.about .container {
  position: relative;
  z-index: 10;
}

.about .content {
  padding: 30px 30px 30px 0;
}

.about .content h2 {
  font-weight: 700;
  font-size: 34px;
  color: #f6c324;
  margin-bottom: 30px;
  font-display: fallback;
}

.about .content p {
  margin-bottom: 30px;
}

.about .content .about-btn {
  display: inline-block;
  background: #010203;
  padding: 6px 44px 8px 30px;
  color: #fff;
  border-radius: 50px;
  transition: 0.3s;
  position: relative;
}

.about .content .about-btn i {
  font-size: 18px;
  font-display: fallback;
  position: absolute;
  right: 18px;
  top: 9px;
}

.about .content .about-btn:hover {
  background: #303030;
  color: #f6c324;
}
.testimonials .testimonial-item p {
  color: #010203;
}

.about .icon-boxes .icon-box {
  margin-top: 30px;
}

.about .icon-boxes .icon-box i {
  font-size: 40px;
  font-display: fallback;
  margin-bottom: 10px;
}

.about .icon-boxes .icon-box:hover {
  transition: 0.3s;
  color: #f6c324;
}

.about .icon-boxes .icon-box h3 {
  font-size: 20px;
  font-display: fallback;
  font-weight: 700;
  margin: 0 0 10px 0;
}

.about .icon-boxes .icon-box p {
  font-size: 15px;
  color: #848484;
  font-display: fallback;
}

@media (max-width: 1200px) {
  .about .content {
    padding-right: 0;
  }
}

@media (max-width: 768px) {
  .about {
    text-align: center;
  }
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .grid {
  display: grid;
  grid-template-rows: masonry;
  grid-template-columns: repeat(2, 1fr);
}
.services .icon-box {
  padding: 30px;
  position: relative;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 0 29px 0 rgba(68, 88, 144, 0.12);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 1;
}

.services .icon-box::before {
  content: '';
  position: absolute;
  background: white;
  right: 0;
  left: 0;
  bottom: 0;
  top: 100%;
  transition: all 0.3s;
  z-index: -1;
}

.services .icon-box:hover::before {
  background: #010203;
  top: 0;
  border-radius: 0px;
}

.services .icon {
  margin-bottom: 15px;
}

.services .icon i {
  font-size: 48px;
  font-display: fallback;
  line-height: 1;
  color: #010203;
  transition: all 0.3s ease-in-out;
}

.services .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
  font-display: fallback;
}

.services .title a {
  color: #111;
}

.services .description {
  font-size: 15px;
  font-display: fallback;
  line-height: 28px;
  margin-bottom: 0;
}

.services .readMoreButton {
  border: 0;
  color: #444;
  border-bottom: 2px solid #444;
  background: none;
  font-weight: 600;
  padding-left: 0;
  padding-right: 0;
}

.services .icon-box:hover .title,
.services .icon-box:hover .icon i,
.services .icon-box:hover .readMoreButton {
  color: #ffd700;
}
.services .icon-box:hover .readMoreButton {
  border-bottom-color: #ffd700;
}

.services .icon-box:hover .description {
  color: #fff;
}

/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts {
  padding-bottom: 30px;
}

.counts .count-box {
  padding: 30px;
  width: 100%;
}

.counts .count-box i {
  display: block;
  font-size: 44px;
  font-display: fallback;
  color: #010203;
  float: left;
  line-height: 0;
}

.counts .count-box span {
  font-size: 48px;
  font-display: fallback;
  line-height: 40px;
  display: block;
  font-weight: 700;
  color: whitesmoke;
  margin-left: 60px;
}

.counts .count-box p {
  padding: 15px 0 0 0;
  margin: 0 0 0 60px;
  font-family: 'Raleway', sans-serif;
  font-display: fallback;
  font-size: 14px;
  color: whitesmoke;
}

.counts .count-box a {
  font-weight: 600;
  display: block;
  margin-top: 20px;
  color: whitesmoke;
  font-size: 15px;
  font-display: fallback;
  font-family: 'Poppins', sans-serif;
  transition: ease-in-out 0.3s;
}

.counts .count-box a:hover {
  color: #6e9fb4;
}

/*--------------------------------------------------------------
# Cta
--------------------------------------------------------------*/
.cta {
  /* linear-gradient(rgba(103, 176, 209, 0.8), rgba(103, 176, 209, 0.8)),*/
  background: url('./img/cta-bg.webp') fixed center center;
  background-size: cover;
  padding: 60px 0;
}

.cta h3 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
  font-display: fallback;
}

.cta p {
  color: #fff;
}

.cta .cta-btn {
  font-family: 'Raleway', sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  font-display: fallback;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 28px;
  border-radius: 25px;
  transition: 0.5s;
  margin-top: 10px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  color: #fff;
}

.cta .cta-btn:hover {
  border-color: #fff;
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio .portfolio-item {
  margin-bottom: 30px;
}

.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 35px auto;
  list-style: none;
  text-align: center;
  background: #010203;
  border-radius: 50px;
  padding: 2px 15px;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 10px 12px 10px 12px;
  font-size: 14px;
  font-weight: 600;
  font-display: fallback;
  line-height: 1;
  text-transform: uppercase;
  color: whitesmoke;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  color: #f6c324;
  background-color: whitesmoke;
  border-radius: 15px;
  transition: all 0.3s ease-in-out;
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
}

.portfolio .portfolio-wrap::before {
  content: '';
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
}

.portfolio .portfolio-wrap .portfolio-links {
  opacity: 1;
  left: 0;
  right: 0;
  bottom: -60px;
  z-index: 3;
  position: absolute;
  transition: all ease-in-out 0.3s;
  display: flex;
  justify-content: center;
}

.portfolio .portfolio-wrap .portfolio-links a {
  color: #fff;
  font-size: 28px;
  text-align: center;
  font-display: fallback;
  background: #010203;
  transition: 0.3s;
  width: 100%;
}

.portfolio .portfolio-wrap .portfolio-links a:hover {
  background: #464646;
}

.portfolio .portfolio-wrap .portfolio-links a + a {
  border-left: 1px solid #010203;
}
/*
.portfolio .portfolio-wrap:hover::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
}
*/

.portfolio .portfolio-wrap:hover .portfolio-links {
  opacity: 1;
  bottom: 0;
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
  width: 100%;
}
/*
@media screen and (min-width: 640px) {
  .swiper-container {
    width: 640px;
  }
}

@media screen and (min-width: 768px) {
  .swiper-container {
    width: 768px;
  }
}
*/
.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #67b0d1;
}

.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet-active {
  background-color: #67b0d1;
}

.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(47, 77, 90, 0.08);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  font-display: fallback;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
  font-display: fallback;
}

.portfolio-details .portfolio-info ul li + li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 30px;
}

.portfolio-details .portfolio-description h1 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
  font-display: fallback;
}

.portfolio-details .portfolio-description p {
  padding: 0;
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials .testimonial-item .testimonial-img {
  width: 200px;
  padding: 20px;
  background-color: white;
  margin: 0 auto;
}

.logoPartner {
  width: 50%;
  height: 50%;
}

.logoSection {
  /*background-color: rgba(0, 0, 0, 0.6);*/
  text-shadow: 2px 2px 5px black;
  padding-top: 8px;
  padding-left: 10px;
  padding-right: 10px;
  height: 8rem;
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  font-display: fallback;
  margin: 10px 0 5px 0;
  color: #111;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  font-display: fallback;
  color: #999;
  margin: 0;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: #d5e9f2;
  font-size: 26px;
  font-display: fallback;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}

.testimonials .testimonial-item p {
  font-style: italic;
  font-display: fallback;
  margin: 0 15px 15px 15px;
  padding: 20px;
  background: #fff;
  position: relative;
  margin-bottom: 35px;
  border-radius: 6px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.testimonials .testimonial-item p::after {
  content: '';
  width: 0;
  height: 0;
  border-top: 20px solid #fff;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  position: absolute;
  bottom: -20px;
  left: calc(50% - 20px);
}

.must-right {
  text-align: right !important;
}

.technologies-item {
  background-color: #67b0d1;
  font-style: italic;
  font-display: fallback;
  margin: 0 25px 25px 25px;
  padding: 20px;
  background: #fff;
  position: relative;
  margin-bottom: 35px;
  border-radius: 6px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid white;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #f7d76c;
}

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team .member {
  text-align: center;
  margin-bottom: 80px;
  position: relative;
}

.member:hover {
  transition: 0.3s;
  background-color: #444444;
  border-radius: 25px;
}

.team .member .pic {
  overflow: hidden;
}

.team .member .member-info {
  position: absolute;
  top: 85%;
  left: 20px;
  right: 20px;
  background: #fff;
  padding: 20px 10px;
  color: #2f4d5a;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-height: 110px;
  height: 100%;
  transition: max-height 0.5s ease-in-out;
}

.team .member h3 {
  font-weight: 700;
  font-display: fallback;
  margin-bottom: 10px;
  font-size: 16px;
  color: #f6c324;
  position: relative;
  padding-bottom: 10px;
}

.team .member h3::after {
  content: '';
  position: absolute;
  display: block;
  width: 50px;
  height: 1px;
  background: #f6c324;
  bottom: 0;
  left: calc(50% - 25px);
}

.team .member span {
  font-style: italic;
  font-display: fallback;
  display: block;
  font-size: 14px;
}

.team .member .social {
  margin-top: 15px;
}

.team .member .social a {
  transition: color 0.3s;
  color: #52869d;
}

.team .member .social a:hover {
  color: #67b0d1;
}

.team .member .social i {
  font-size: 16px;
  font-display: fallback;
  margin: 0 2px;
}

@media (max-width: 992px) {
  .team .member {
    margin-bottom: 110px;
  }
}

.whiteBox {
  background-color: white;
  height: 80px;
  width: 200px;
}

/*--------------------------------------------------------------
# Cooperation
--------------------------------------------------------------*/

.cooperation .cooperation-card {
  background-color: white;
  border-radius: 25px;
}

.cooperation .img-wrapper {
  width: 100%;
}

.cooperation .img-wrapper img {
  max-width: 100%;
}

.cooperation .text-wrapper {
  width: 100%;
}

img[alt='Go4Energy logo'] {
  width: 30%;
}

img[alt='Alhambra logo'] {
  width: 70%;
}

@media (min-width: 768px) {
  .cooperation .img-wrapper {
    width: 30%;
  }

  .cooperation .text-wrapper {
    width: 70%;
  }

  img[alt='Go4Energy logo'] {
    width: 65%;
  }

  img[alt='Alhambra logo'] {
    width: 100%;
  }
}

.cooperation h2 {
  color: white;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/

.contact-link {
  color: #f6c324;
}

.contact {
  padding-bottom: 80px;
}

.contact {
  background: url('./img/dark-plaster-pattern.webp') top center;
  background-size: cover;
  position: relative;
}
.contact .info-box {
  color: #444444;
  background: #fff;
  text-align: center;
  box-shadow: 0 0 15px rgba(214, 215, 216, 0.6);
  padding: 20px 0 30px 0;
}

.contact .map {
  padding: 0;
  height: 384px;
}

.contact .info-box i {
  font-size: 32px;
  font-display: fallback;
  color: grey;
  padding: 8px;
}

.contact .info-box h3 {
  font-size: 20px;
  font-display: fallback;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
}

.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  font-display: fallback;
  margin-bottom: 0;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.Contact > {
  background-color: black !important;
}
/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: url('./img/cables-min.webp') top center;
  background-size: cover;
  position: relative;
  padding: 0 0 30px 0;
  color: #fff;
  font-size: 14px;
  font-display: fallback;
}

#footer .footer-top {
  padding: 60px 0 30px 0;
}

#footer .footer-top .footer-info {
  margin-bottom: 15px;
  background: white;
  color: #2f4d5a;
  border-top: 4px solid #f6c324;
  text-align: center;
  padding: 30px 20px;
}

#footer .footer-top .footer-info h3 {
  font-size: 24px;
  font-display: fallback;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
}

#footer .footer-top .footer-info p {
  font-size: 14px;
  font-display: fallback;
  line-height: 24px;
  margin-bottom: 0;
  font-family: 'Raleway', sans-serif;
}

#footer .footer-top .social-links a {
  font-size: 18px;
  font-display: fallback;
  display: inline-block;
  background: #2f4d5a;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: #67b0d1;
  color: #fff;
  text-decoration: none;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-display: fallback;
  font-weight: 600;
  color: #fff;
  position: relative;
  padding-bottom: 12px;
}
/*
.footer-links {
  background-color: black;
  padding-top: 10px;
}
*/
#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #b5d9e9;
  font-size: 18px;
  font-display: fallback;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #fff;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
  color: #a2cfe3;
}

#footer .footer-top .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 4;
}

#footer .footer-top .footer-newsletter form input[type='email'] {
  border: 0;
  padding: 4px;
  width: calc(100% - 110px);
}

#footer .footer-top .footer-newsletter form input[type='submit'] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  font-display: fallback;
  padding: 0 20px;
  background: #ffd700;
  color: #fff;
  transition: 0.3s;
  border-radius: 4;
}

#footer .footer-top .footer-newsletter form input[type='submit']:hover {
  background: #409cc5;
}

#footer .copyright {
  border-top: 2px solid #f6c324;
  text-align: center;
  padding-top: 10px;
  background-color: rgba(50, 50, 50, 0.7);
  padding-bottom: 10px;
}

#footer .credits {
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
  font-display: fallback;
  color: #fff;
}

@media (max-width: 575px) {
  #footer .footer-top .footer-info {
    margin: -20px 0 30px 0;
  }
}

.testimonials .testimonial-item p {
  color: #010203;
}

.testimonials .testimonial-item p {
  font-style: italic;
  font-display: fallback;
  margin: 0 15px 15px 15px;
  padding: 20px;
  background: #fff;
  position: relative;
  margin-bottom: 35px;
  border-radius: 6px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.testimonials .testimonial-item p::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 20px solid #fff;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  position: absolute;
  bottom: -20px;
  left: calc(50% - 20px);
}

.technologies-item {
  background-color: #67b0d1;
  font-style: italic;
  font-display: fallback;
  margin: 0 25px 25px 25px;
  padding: 20px;
  background: #fff;
  position: relative;
  margin-bottom: 35px;
  border-radius: 6px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.portfolio .portfolio-wrap .portfolio-links {
  opacity: 1;
  left: 0;
  right: 0;
  bottom: -60px;
  z-index: 3;
  position: absolute;
  transition: all ease-in-out 0.3s;
  display: flex;
  justify-content: center;
}

.portfolio .portfolio-wrap .portfolio-links a {
  color: #fff;
  font-size: 28px;
  text-align: center;
  font-display: fallback;
  background: #010203;
  transition: 0.3s;
  width: 100%;
}

.portfolio .portfolio-wrap .portfolio-links a:hover {
  background: #464646;
}

.portfolio .portfolio-wrap .portfolio-links a + a {
  border-left: 1px solid #010203;
}
.portfolio .portfolio-wrap .portfolio-links a:hover {
  background: #464646;
}

.portfolio .portfolio-wrap .portfolio-links a + a {
  border-left: 1px solid #010203;
}
/*
  .portfolio .portfolio-wrap:hover::before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
  }
  */

.portfolio .portfolio-wrap:hover .portfolio-links {
  opacity: 1;
  bottom: 0;
}

.testimonials .testimonial-item {
  box-sizing: content-box;
  text-align: center;
  min-height: 270px;
}

.testimonials .testimonial-item .testimonial-img {
  width: 200px;
  padding: 20px;
  background-color: white;
  margin: 0 auto;
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  font-display: fallback;
  margin: 10px 0 5px 0;
  color: #111;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: #d5e9f2;
  font-size: 26px;
  font-display: fallback;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}

.testimonials .testimonial-item p {
  font-style: italic;
  font-display: fallback;
  margin: 0 15px 15px 15px;
  padding: 20px;
  background: #fff;
  position: relative;
  margin-bottom: 35px;
  border-radius: 6px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.testimonials .testimonial-item p::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 20px solid #fff;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  position: absolute;
  bottom: -20px;
  left: calc(50% - 20px);
}

.react-viewer-close {
  width: 4.5rem !important;
  height: 4.5rem !important;
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.react-viewer-close .react-viewer-icon {
  color: #f7d76c;
  font-size: 20px;
  margin-top: 1.3rem;
  margin-left: 0.5rem;
}

.refImg {
  height: 85vh;
  width: 85vw;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.close-btn {
  width: 50vw;
  margin-top: 5px;
}

/* CSS */
.close-btn {
  appearance: none;
  background-color: #000000;
  border: 2px solid #1a1a1a;
  border-radius: 15px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  min-height: 60px;
  min-width: 0;
  outline: none;
  padding: 16px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;
}

.close-btn:disabled {
  pointer-events: none;
}

.close-btn:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.close-btn:active {
  box-shadow: none;
  transform: translateY(0);
}
